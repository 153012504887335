import { useCallback, useEffect, useMemo } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';
import { getBrokerageAccountSetupState, getExternalAccountSetupState } from '@arrived/common';
import { UserDocumentType } from '@arrived/models';
import { useGetAccountKycAmlQuery, useGetPrimaryAccountQuery, useUserDocumentsQuery } from '@arrived/queries';
import { Sentry } from '@arrived/sentry';

import { VERIFICATION_STATE } from '../models';
import { createVerificationSteps } from '../VerificationSteps';
import { handleBankLinkVerification } from './handleBankLinkVerification';
import { handleBrokerageAccountVerification } from './handleBrokerageAccountVerification';
import { handleEmailVerification } from './handleEmailVerification';
import { handleEndOverrides } from './handleEndOverrides';
import { handleIdentityVerification } from './handleIdentityVerification';

export const useVerificationStepperSteps = () => {
  const { user, getCredentials, isLoading: isAuthLoading } = useArrivedAuth0();
  const accountState = useGetPrimaryAccountQuery();
  // TODO: Here use the refetch interval to poll the BE if the user is in PENDING_SYNC for the KycAmlRollupStatus
  const accountKycAmlState = useGetAccountKycAmlQuery(accountState.data?.id);
  const documentsState = useUserDocumentsQuery(accountState.data?.primaryUser?.id);
  const isLoading = accountState.isFetching || accountKycAmlState.isFetching;
  const brokerageAccountSetupState = getBrokerageAccountSetupState(accountState.data, accountKycAmlState.data);
  const externalAccountSetupState = getExternalAccountSetupState(accountState.data?.externalAccount);

  const hasIdDocument = useMemo(
    () => Boolean(documentsState.data?.find((document) => document.type !== UserDocumentType.BANK_VERIFICATION)),
    [documentsState],
  );

  /**
   * In the event of an incident where abacus does not respond as usual, we don't want to show the
   * user the wrong state. Without hiding the stepper, a query may time out and we incorrectly tell
   * them that their KYC has suddenly failed, or their account doesn't exist.
   *
   * The accountState will be the common denominator for all users in all setup states, hence
   * targeting that 404 condition vs including the other queries.
   */
  const shouldHideStepperDueToAccountStateError = accountState.isError && accountState.error?.status !== 404;

  const steps = useMemo(() => {
    const passedSteps = createVerificationSteps();

    handleEmailVerification(passedSteps.map, user?.email, user?.emailVerified);
    handleBrokerageAccountVerification(passedSteps.map, accountState.data);
    handleIdentityVerification(passedSteps.map, brokerageAccountSetupState, hasIdDocument);
    handleBankLinkVerification(passedSteps.map, externalAccountSetupState);
    handleEndOverrides(passedSteps.map, externalAccountSetupState, brokerageAccountSetupState, hasIdDocument);

    return passedSteps;
  }, [
    accountState.data,
    user?.email,
    user?.emailVerified,
    brokerageAccountSetupState,
    externalAccountSetupState,
    hasIdDocument,
  ]);

  const isVerified = useMemo(() => steps.ordered.every((step) => step.state === VERIFICATION_STATE.COMPLETE), [steps]);

  /**
   * Refetch the account state, document state and kyc aml state.
   * This is used to update the verification steps when the user
   * is logged, is not verified and we need to update the UI.
   */
  const refetchVerificationSteps = useCallback(() => {
    if (isVerified) {
      return Promise.resolve();
    }

    return Promise.all([getCredentials, accountState.refetch, documentsState.refetch, accountKycAmlState.refetch]).then(
      () => {
        Sentry.addBreadcrumb({
          message: 'Verification steps refetched after auth',
          category: Sentry.BreadcrumbCategories.Verification,
          level: 'info',
          data: {
            user: user?.email,
            steps: steps.ordered.map((step) => step.state),
          },
        });
      },
    );
  }, [getCredentials, user, steps, isVerified]);

  const onAppStateChange = useCallback((state: AppStateStatus) => {
    if (state === 'active') {
      getCredentials();
    }
  }, []);

  useEffect(() => {
    // We want to refetch the user if they are not verified, but only if we have
    // the user data. This is to prevent a loop of refetching
    if (!isAuthLoading && user?.emailVerified === false) {
      getCredentials();
    }
  }, [isAuthLoading, user]);

  useEffect(() => {
    // Reset everything at first, just because there is a chance for the user to be unverified
    getCredentials();

    // When a user leaves to another app and comes back, we want to refetch the user
    // to make sure we have the latest data. This is important for the verification
    // steps to be updated (specifically email verification)
    const subscription = AppState.addEventListener('change', onAppStateChange);

    return () => subscription.remove();
  }, []);

  return { isVerified, isLoading, shouldHideStepperDueToAccountStateError, steps, refetchVerificationSteps };
};
